<template>
  <div id="data-list-list-view" class="data-list-container">
    <h3>Funktions grupper - (Unik Bolig rettigheder)</h3>

    <vs-table
      @selected="onProfileClicked(selected)"
      ref="table"
      class="mt-8"
      v-model="selected"
      pagination
      :max-items="itemsPerPage"
      search
      :data="profiles"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <div
          class="flex flex-wrap-reverse items-center data-list-btn-container"
        >
          <!-- ADD NEW -->
          <div
            @click="$router.push({ name: 'function' }).catch(() => {})"
            class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary"
          >
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
            <span class="ml-2 text-base text-primary">Opret ny funktions gruppe</span>
          </div>

          
        </div>

        <!-- ITEMS PER PAGE -->
        <vs-dropdown
          vs-trigger-click
          class="cursor-pointer mb-4 mr-4 items-per-page-handler"
        >
          <div
            class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
              {{
                profiles.length - currentPage * itemsPerPage > 0
                  ? currentPage * itemsPerPage
                  : profiles.length
              }}
              of {{ queriedItems }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage = 10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 20">
              <span>20</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 40">
              <span>40</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 100">
              <span>100</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="organisationsId">Afdeling</vs-th>
        <vs-th sort-key="organisationNavn">Rolle</vs-th>
        <vs-th sort-key="type">Bruger type</vs-th>
        <vs-th sort-key="type">Bruger status</vs-th>
        <vs-th sort-key="type">Bogførring rettigheder</vs-th>
        <vs-th sort-key="type">Unik Funktions grupper</vs-th>

        <vs-th></vs-th>
      </template>


      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <p class="product-name font-medium xtruncate">
                {{tr.afdeling}}
              </p>
            </vs-td>
            <vs-td>
              <p>{{tr.afdelingRolle}}</p>
            </vs-td>
            <vs-td>
              <p>{{ tr.brugerType }}</p>
            </vs-td>
            <vs-td>
              <p>{{ tr.brugerStatus }}</p>
            </vs-td>
            <vs-td>
              <p v-if="tr.bogforringRettighed">Ja</p>
              <p v-else>Nej</p>
            </vs-td>
            <vs-td>
              <p>{{ tr.funktioner}}</p>
            </vs-td>

            <vs-td class="whitespace-no-wrap">
              <feather-icon
                icon="EditIcon"
                svgClasses="w-5 h-5 hover:text-primary stroke-current"
                @click.stop="onProfileClicked(tr)"
                title="Rediger Funktionsgruppe"
              />
             
              <feather-icon
                icon="CopyIcon"
                svgClasses="w-5 h-5 hover:text-primary stroke-current"
                class="ml-2"
                @click.stop="onCopyClicked(tr)"
                title="Kopier til ny profil"
              />
              <!-- <feather-icon
                icon="TrashIcon"
                svgClasses="w-5 h-5 hover:text-danger stroke-current"
                class="ml-2"
                @click.stop="confirmDelete(tr)"
              /> -->
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      query: "*",

      profiles: [],
      departments: [],

      selected: [],
      itemsPerPage: 20,
      isMounted: false
    };
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },

    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.profiles.length;
    }
  },
  methods: {
    getProfiles: function() {
      this.$vs.loading();

      this.$http
        .get(process.env.VUE_APP_API + "/FunctionGroups/all")
        .then(resp => {
          this.profiles = resp.data;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },
    onProfileClicked(profile) {
      this.$router
        .push({ name: "function", params: { functionId: profile.id } })
        .catch(() => {});
    },
    onCopyClicked(profile) {
      this.$router
        .push({ name: "function", query: { copyFromId: profile.id } })
        .catch(() => {});
    },
    deleteData(id) {
      this.$vs.loading();

      this.$http
        .delete(process.env.VUE_APP_API + "/FunctionGroups/" + id)
        .then(resp => {
          if (resp.data.success === true) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Profil slettet"
            });
            this.getProfiles();
          } else {
            console.log(resp.data.error);

            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: "Profil kunne ikke slettes: " + resp.data.error
            });
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },
    confirmDelete(profile) {
      this.$vs.dialog({
        type: "confirm",
        title: `Slet afdeling`,
        text: `Er du sikker på at du vil slette ${profile.displayName}?`,
        accept: this.acceptAlert,
        parameters: [profile.profileId]
      });
    },
    acceptAlert: function(parameters) {
      this.deleteData(parameters[0]);
    }
  },
  created() {
    this.getProfiles();
  },
  mounted() {
    this.isMounted = true;
  }
};
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>